import React, { FC } from 'react'
import { OutcomesView } from './OutcomesView'
import { useGetSubstantiatedTypeMapping } from './OutcomesApi'
import './outcomes.scss'
import { Spinner } from '../Shared/Spinner'
import { UnexpectedError } from '../Shared/Error'
import { DefaultPage } from 'navex-react'
import { TOGGLENAME } from '../../ts/enums/releaseToggles'

interface IProps {
  toggles: string[]
}

export const OutcomesRoute:FC<IProps> = (props) => {
  
  const [{ mappings, options, loading, status, errors }] = useGetSubstantiatedTypeMapping(props.toggles.includes(TOGGLENAME.POWERBI_V2))

  if (errors.length) return <UnexpectedError />
  if (loading || !status) return <Spinner />

  return (<DefaultPage>
    <OutcomesView mappings={mappings} options={options} /> 
  </DefaultPage>)
}
