export enum TOGGLEID {
}

export enum TOGGLENAME {
  POWERBIDASHBOARDS = "PowerBiDashboards",
  BIRSTDASHBOARDS = "BirstDashboards",
  BIRSTPERMISSIONS = "BirstPermissions",
  BIRSTDASHBOARDMANAGEMENT = "BirstDashboardManagement",
  BIRSTLEFTNAV = "BirstLeftNav",
  POWERBISHAREDASHBOARD = "PowerBiShareDashboard",
  POWERBIPERMISSIONS = "PowerBiPermissions",
  POWERBIDATASETMANAGEMENT = "PowerBiDatasetManagement",
  POWERBILEFTNAV = "PowerBiLeftNav",
  POWERBIREPORTMANAGEMENT = "PowerBiReportManagement",
  POWERBICUSTOMFIELDMAPPING = "PowerBiCustomFieldMapping",
  SUBSCRIBEBYEMAIL = "SubscribeByEmail",
  CROSSPRODUCT ="CrossProduct",
  POWERBI_V2= "PowerBI_V2",
  AUTHLIBASMCUTOVER = "AuthLib_AsmCutover",
  DATEDIFFCALCULATION = "DateDiffCalculation",
  NAVEXENGAGEREPORTS = "NavexEngageReports"
}