import { models, Report } from 'powerbi-client'
import { PowerBIEmbed } from 'powerbi-client-react'
import React, { useEffect, FC, useState } from 'react'
import { getCustomerKey, pathPrefix } from '../../Api/urls'
import { UnexpectedError } from '../Shared/Error'
import { Spinner } from '../Shared/Spinner'
import { useGetEmbedInfo, getnewReportRoles } from './PowerBIEmbeddedDashboardAPI'
import { useGetRoles } from '../RolesV2/RolesApi'
import { ShareReport } from './ShareReport'
import './report.css'
import { TOGGLENAME } from '../../ts/enums/releaseToggles'
import { FavoriteReport } from './FavoriteReport'
import { IPowerBiReports } from '../../Models/GlobalState'
import ExportReport from './ExportReport'
import { Button, ButtonRow, IButtonProps, Modal, ToastId, Toasts } from 'navex-react'
import { getReportsFromPath } from '../BirstEmbeddedDashboard/birstUtils'
import { SubscribeButton } from './SubscribeButton'
import { createBrowserHistory } from "history"
import { IGetSubscription, useGetSubscriptionDetails, useUpdateSubscription } from '../Subscription/SubscriptionApi'
import styles from '../Subscription/Subscription.module.scss'

interface IProps {
    toggles: string[],
    productPermissions: string[],
    reportsLeftNavMenu: IPowerBiReports[]
    email :string,
    SubscriptionDetails: IGetSubscription[],
}

export const PowerBIEmbeddedDashboard: FC<IProps> = (props) => {
    const insightAppHistory = createBrowserHistory({ forceRefresh: true })
    const [reportId, setReportId] = useState('')
    const [reportHasError, setReportHasError] = useState(false)
    const [{ roles, errors: rolesErrors }] = useGetRoles(props.toggles.includes(TOGGLENAME.POWERBI_V2))
    const [exportSpinner, setExportSpinner] = useState(false)
    const [reportRoleId, setReportRoleIds] = useState({ favorite: '', roles: [] } as any)
    const [subscribeModal, setSubscribeModal] = useState(false)
    const [fieldRequestedForUnsubscribe, setFieldRequestedForUnsubscribe] = useState("")
    const [okButtonProps, setOkButtonProps] = useState({} as IButtonProps)
    const [showModalUnsubscribe, setShowModalUnsubscribe] = useState(false)
    const [ModalText, setModalText] = useState(<p>Modal Text</p>)
    const open = () => setShowModalUnsubscribe(true)
    const close = () => setShowModalUnsubscribe(false)
    const [getEmbedInfo, { posting, status, embedInfo, errors }] = useGetEmbedInfo({
        onSuccess: () => { },
        onError: () => { }
    })

    let toastId: ToastId = 0
    const [update] = useUpdateSubscription({
        onSuccess: () => {
            toastId = Toasts.success("Unsubscribed Successfully")
            reFetchData()
            setShowModalUnsubscribe(false)
        },
        onError: () => {
            if (!Toasts.isActive(toastId)) {
                toastId = Toasts.alert("An error occurred processing your request. Please try again.")
            }
        }
    })

    const refetchReportRoles = () => {
        const a = getnewReportRoles(reportId)
        a.then((res) => {
            if (res) {
                setReportRoleIds(res)
            }
        })
    }

    const onClickTakeToManageSubscription = () => {

        insightAppHistory.push(`${pathPrefix}/Manage_Subscription/${reportId}`, {})
    }
    useEffect(() => {
        const { reportName, pbiCollectionName } = getReportsFromPath(window.location.pathname)
        let reportIdfromCollection = props.reportsLeftNavMenu.find(rl => rl.leftNavName === decodeURIComponent(pbiCollectionName))?.powerBiReports.find((report) => report.displayName === decodeURIComponent(reportName))?.reportId
        if (reportIdfromCollection && reportIdfromCollection !== reportId) {
            setReportId(reportIdfromCollection)
            getEmbedInfo({
                customerKey: getCustomerKey(),
                reportId: reportIdfromCollection
            })
            const a = getnewReportRoles(reportIdfromCollection)
            a.then((res) => {
                if (res) {
                    setReportRoleIds(res)
                }
            })
        }
    }, [reportId])

    const pbiCollectionName = decodeURIComponent(getReportsFromPath(window.location.pathname).pbiCollectionName)
    const reportName = decodeURIComponent(getReportsFromPath(window.location.pathname).reportName).replace(/%20/g, ' ')
    const [{ SubscriptionDetails, loading: SubscriptionLoading, errors: SubscriptionError, status: SubscriptionStatus }, reFetchData] = useGetSubscriptionDetails()

    if (errors.length || SubscriptionError.length) return <UnexpectedError />
    if (posting || !status || SubscriptionLoading || !SubscriptionStatus) return <Spinner />

    const isSubscribed = SubscriptionDetails && SubscriptionDetails.some(
        (subscription: any) => subscription.reportName === reportName && subscription.recipients.includes(props.email)
    );

    const Unsubscribe = async () => {
        open()
        let buttonProps = {} as IButtonProps
        buttonProps.disabled = false
        setOkButtonProps(buttonProps)
        setModalText(
            <>
                Are you sure you wish to unsubscribe "{reportName}"?
                <div className={styles.alignCenter}>
                </div></>)
    }
    
    const handleUnsubscribe = async () => {
        const subscriptionDetails = SubscriptionDetails.find((subscription: any) => subscription.reportName === reportName && subscription.recipients.includes(props.email))
        const recipients = subscriptionDetails && subscriptionDetails.recipients.split(',')
        const removeRecipient = recipients && recipients.filter((recipient) => recipient !== props.email).join(',')
        
        subscriptionDetails && removeRecipient?
        await update({
            SubscriptionId: subscriptionDetails.subscriptionId,
            ReportId: reportId,
            SubscribeName: subscriptionDetails.subscriptionName,
            RecipientMailId: removeRecipient,
            ReportTypeFormat: subscriptionDetails.reportTypeFormat,
            SubscriptionStartDate: subscriptionDetails.subscriptionStartDate,
            Frequency: subscriptionDetails.frequency,
            Timezone: subscriptionDetails.timezone,
            ReportUrl: window.location.href,
            WeekOfFrequency: subscriptionDetails.frequency === "Weekly" ? subscriptionDetails.weekOfFrequency : null,
            MonthOfFrequency: subscriptionDetails.frequency === "Monthly" ? subscriptionDetails.monthOfFrequency : null,
            author: subscriptionDetails.author
        }) : null
    }

    return (<>
        {reportHasError ? (
            <UnexpectedError errorKey={0} />
        ) : (
            <>
                <ButtonRow alignment='right' style={{ paddingBottom: '4px', paddingTop: '4px' }}>
                    {props.productPermissions.includes('Favorite') ?
                        <FavoriteReport toggles={props.toggles} reportId={reportId} customerKey={getCustomerKey()} favorite={reportRoleId.favorite} /> : false
                    }
                    {props.toggles.includes(TOGGLENAME.POWERBISHAREDASHBOARD) && ((pbiCollectionName === "Incident Management" && props.productPermissions.includes('EpimShare')) ||
                        (pbiCollectionName === "Disclosure Management" && props.productPermissions.includes('DmShare')) ||
                        (pbiCollectionName === "RiskRate" && props.productPermissions.includes('RiskRateShare')) ||
                        (pbiCollectionName === "NAVEX One Insights" && props.productPermissions.includes('CpShare'))) ?
                        <ShareReport toggles={props.toggles} roles={roles} errors={rolesErrors} reportId={reportId} customerKey={getCustomerKey()} reportRoleIds={reportRoleId.roles} refetchReportRoles={refetchReportRoles} /> : false
                    }
                    {(pbiCollectionName === "Incident Management" && props.productPermissions.includes('EpimExport')) ||
                        (pbiCollectionName === "Disclosure Management" && props.productPermissions.includes('DmExport')) ||
                        (pbiCollectionName === "RiskRate" && props.productPermissions.includes('RiskRateExport')) ||
                        (pbiCollectionName === "NAVEX One Insights" && props.productPermissions.includes('CpExport')) ?
                        <ExportReport reportName={decodeURIComponent(window.location.href.substring(window.location.href.lastIndexOf("_") + 1))} reportId={reportId} showText={true} customerKey={getCustomerKey()} setExportSpinner={setExportSpinner} forReport={true} /> : false
                    }
                    {props.toggles.includes(TOGGLENAME.SUBSCRIBEBYEMAIL) ? 
                        isSubscribed ? <Button onClick={() => Unsubscribe()}>Unsubscribe</Button> :
                        <Button onClick={() => onClickTakeToManageSubscription()}>Subscribe</Button> : false
                    }
                    <SubscribeButton reportId={reportId} subscribeModal={subscribeModal} setSubscribeModal={setSubscribeModal} />
                </ButtonRow>
                <PowerBIEmbed
                    embedConfig={{
                        type: 'report',
                        id: embedInfo.id,
                        embedUrl: embedInfo.embedUrl,
                        accessToken: embedInfo.token,
                        tokenType: models.TokenType.Embed,
                        viewMode: models.ViewMode.View,
                        permissions: models.Permissions.All,
                        settings: {
                            hideErrors: true,
                            panes: {
                                bookmarks: {
                                    visible: false
                                },
                                fields: {
                                    expanded: true
                                },
                                filters: {
                                    expanded: true,
                                    visible: true
                                },
                                pageNavigation: {
                                    visible: true
                                },
                                selection: {
                                    visible: false
                                },
                                syncSlicers: {
                                    visible: true
                                },
                                visualizations: {
                                    expanded: true
                                }
                            }
                        }
                    }}

                    eventHandlers={
                        new Map([
                            ['loaded', function () { console.log('Report loaded'); }],
                            ['rendered', function () { console.log('Report rendered'); }],
                            ['error', function (event) {
                                console.log(event!.detail);
                                setReportHasError(true);
                            }]
                        ])
                    }

                    cssClassName={"report"}

                    getEmbeddedComponent={(embeddedReport) => {
                        window.report = embeddedReport as Report
                    }}
                />
                <Modal
                id="unsubscribeModal"
                show={showModalUnsubscribe}
                okButtonProps={okButtonProps}
                okButtonText="Yes"
                message={ModalText}
                headerText={"Unsubscribe"}
                onClickedOk={() => handleUnsubscribe()}
                onClickedCancel={close}
                />
            </>
        )}
    </>
    )
}



