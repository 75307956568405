import React from "react"
import { AppNavigationUI, IAppNavigationUIRoute } from "navex-react"
import { buildUrlForVerifyingHumanSession, getCustomerKey, pathPrefix, buildUrlForToggleStatus, buildUrlForApiAuthRedirectToKeyCloakV3, buildUrlForGetTenantInfo, setTenantId, setCustomerKey } from "../../Api/urls"
import { faChartBar, faLock, faStar } from "@fortawesome/free-solid-svg-icons"
import { IAuthenticationState, IFeatureConfigurations, IGlobalState } from "../../Models/GlobalState"
import { connect } from "react-redux"
import { AuthenticationActionCreators } from "../../Reducers/AuthenticationReducer"
import { computeAdminNavNodes } from "./adminLeftNavNodes"
import { computeBirstDashboardRoutePath, computeDashboardNavTree } from "./dashboardLeftNavNodes"
import { activatePendo } from "../../Assets/js/pendo"
import { Spinner } from "../Shared/Spinner"
import { UnexpectedError, ERRORS, NoDataError } from "../Shared/Error"
import { TOGGLEID, TOGGLENAME } from '../../ts/enums/releaseToggles'
import { BirstEmbeddedDashboard } from "../BirstEmbeddedDashboard/BirstEmbeddedDashboard"
import { BirstIntegrationsActionCreators, ILeftNavClickEvent } from '../../Reducers/BirstIntegrationsReducer'
import { getDashboardsFromPath, getDashboardsFromEditPath, getReportsFromPath } from '../BirstEmbeddedDashboard/birstUtils'
import { InsightsActionCreators } from '../../Reducers/InsightsReducer'
import { computeDashboardManagementLeftNavNodes } from "./dashboardManagementLeftNavNodes"
import { HeaderContents } from './HeaderContents'
import { NoPersmissionError } from "../Shared/Error"
import { axiosInstance } from '../../Api/api'
import { handleStorageEvent } from '../../Utils/utils'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ViewDashboardRoute } from '../ViewDashboard/ViewDashboardRoute'
import { PowerBIEmbeddedDashboard } from '../PowerBIEmbeddedDashboard/PowerBIEmbeddedDashboard'
import { computePowerBiReportRoutePath, computePowerBiDashboardNavTree } from "./powerBiReportLeftNavNodes"
import { FavoriteRoute } from "../Favorite/FavoriteRoute"
import { computeDatasetManagementLeftNavNodes } from './DatasetManagementLeftNav/datasetManagementLeftNavNodes'
import { ManagePowerBIRoute } from "../PowerBIManage/ManagePowerBIRoute"
import { RolesRoute } from "../RolesV2/RolesRoute"
import { SubscriptionRoute } from "../Subscription/SubscriptionRoute"
import { createBrowserHistory } from "history"

export const insightAppHistory = createBrowserHistory()

interface IOwnProps {
}

interface IStateDependentProps extends IAuthenticationState {
    canViewDashboards: boolean,
    authenticationUnexpectedError?: number,
    toggles: TOGGLENAME[],
    currentPage: string,
    featureConfigurations: IFeatureConfigurations[]
}

interface IPropsThatDispatch {
    onAuthenticationConfirmed: (userData: unknown) => void,
    onErrorGettingAuthentication: (errorKey: number) => void,
    pubLeftNavClickEvent: (event: ILeftNavClickEvent) => void,
    setCurrentPage: (value: string) => void,
}

type ComponentProps = IOwnProps & IStateDependentProps & IPropsThatDispatch

type ComponentState = {
    loading: boolean,
    currentSpaceId?: string,
    reportName: string
}



// tslint:disable-next-line:max-classes-per-file
export class InsightsAppUnwrappedForTestingOnly extends React.Component<ComponentProps, ComponentState> {
    pollSession: NodeJS.Timeout | undefined

    constructor(props: ComponentProps) {
        super(props)
        this.state = {
            loading: true,
            currentSpaceId: undefined,
            reportName: ""
        }
    }

    getTenantInfo = async () => {

        let errorKey: number | undefined = undefined

        const url = buildUrlForGetTenantInfo()

        const response = await axiosInstance.get(url).catch(error => {
            if (error) {
                errorKey = ERRORS.DEFAULT_ERROR
            }
            return undefined
        })

        if (response === undefined || errorKey) { this.props.onErrorGettingAuthentication(errorKey || ERRORS.DEFAULT_ERROR) }

        const responseData = {
            ...response && response.data.data
        }

        return response && responseData
    }

    getToggleStatus = async (toggleId: TOGGLEID) => {

        let errorKey: number | undefined = undefined

        const url = `${buildUrlForToggleStatus().toString()}/${getCustomerKey()}/${toggleId}`

        const response = await axiosInstance.get(url).catch(error => {
            if (error) {
                errorKey = ERRORS.DEFAULT_ERROR
            }
            return undefined
        })

        if (response === undefined || errorKey) { this.props.onErrorGettingAuthentication(errorKey || ERRORS.DEFAULT_ERROR) }

        const responseData = {
            ...response && response.data.data
        }

        return response && responseData
    }

    getHumanSessionFromServer = async () => {
        let triesRemaining = 2
        const url = buildUrlForVerifyingHumanSession().toString()

        const pingSessionForSuccess = async () => {
            let errorKey: number | undefined = undefined
            const response = await axiosInstance.get(url).catch(error => {
                if (error.response) {
                    if (error.response.status === 401) {
                        window.location.href = buildUrlForApiAuthRedirectToKeyCloakV3().toString()
                        //Must return null to avoid rendering Error message
                        return null
                    }

                    if (error.response.data!.errors[0].key === 100) {
                        errorKey = ERRORS.DISABLED
                    }

                    if (error.response.data!.errors[0].key === 101) {
                        if (triesRemaining > 0) {
                            triesRemaining--
                            setTimeout(pingSessionForSuccess, 500)
                            //Must return null to avoid rendering Error message
                            return null
                        }
                        else {

                            errorKey = ERRORS.DEFAULT_ERROR
                        }
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    errorKey = ERRORS.DEFAULT_ERROR
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorKey = ERRORS.DEFAULT_ERROR
                }

                return undefined
            })

            // Dont error app on null response, because we are redirecting the browser
            if (response === undefined || errorKey) { this.props.onErrorGettingAuthentication(errorKey || ERRORS.DEFAULT_ERROR) }

            const responseData = {
                ...response && response.data.data
            }

            return response && responseData
        }
        return await pingSessionForSuccess()
    }

    getAuthenticationState = async (): Promise<IAuthenticationState | null> => {
        let serverData = null
        serverData = await this.getHumanSessionFromServer()
        if (!serverData) return null

        const { username, firstName, lastName } = serverData

        if (!(username && firstName && lastName)) {
            throw new Error("Programming error: Response from server does not match expected shape.")
        }

        return serverData as IAuthenticationState
    }

    async componentDidMount() {
        const getTenantInfo = await this.getTenantInfo()

        setTenantId(getTenantInfo.tenantId)

        setCustomerKey(getTenantInfo.customerKey)

        const authData = await this.getAuthenticationState()

        if (authData) {
            this.props.onAuthenticationConfirmed(authData)

            activatePendo(authData.puid, getCustomerKey(), authData.tenantId)

            const { dashboardName } = getDashboardsFromPath(window.location.pathname)

            this.setState({
                loading: false,
            })
            this.props.setCurrentPage(decodeURIComponent(dashboardName))

            localStorage.setItem("insightsActive", "true")

            window.addEventListener('storage', handleStorageEvent)
            window.addEventListener("popstate", () => {
                const { reportName } = getReportsFromPath(window.location.pathname)
                this.props.setCurrentPage(decodeURIComponent(reportName))
                this.setState({ reportName: decodeURIComponent(reportName) })
            })
        }
    }

    UNSAFE_componentWillUnmount() {
        clearInterval(this.pollSession!)
        window.removeEventListener('storage', handleStorageEvent)
    }

    buildDisplayName() {
        if (this.props.firstName && this.props.lastName) {
            return `${this.props.firstName} ${this.props.lastName}`
        }

        return "Signing in..."
    }

    getTitle = () => {
        if (window.location.pathname.includes('DashboardManagement_EditDashboard')) {
            const { dashboardName } = getDashboardsFromEditPath(window.location.pathname)
            return decodeURIComponent(dashboardName)
        }

        if (window.location.pathname.includes('/Reports/')) {
            const { reportName } = getReportsFromPath(window.location.pathname)
            return decodeURIComponent(reportName)
        }
        const { dashboardName } = getDashboardsFromPath(window.location.pathname)
        return decodeURIComponent(dashboardName)
    }

    render() {
        if (this.props.authenticationUnexpectedError !== undefined) {
            return <UnexpectedError errorKey={this.props.authenticationUnexpectedError} />
        }
        if (this.state.loading) { return <Spinner /> }

        if (this.props.dashboardCollections === undefined ||
            this.props.defaultDashboardCollection === undefined ||
            this.props.defaultDashboard === undefined ||
            this.props.permissions === undefined) {

            return <NoDataError />
        }

        if ((window.location.pathname === "/" || window.location.pathname === `${pathPrefix}/`) && this.props.defaultDashboardCollection
            && this.props.defaultDashboardCollection.length > 0 && this.props.defaultDashboard
            && this.props.defaultDashboard.length > 0) {

            insightAppHistory.push(`${pathPrefix}${computeBirstDashboardRoutePath(this.props.defaultDashboardCollection, this.props.defaultDashboard)}`)
        }

        if ((window.location.pathname === '/' || window.location.pathname === `${pathPrefix}/`) && this.props.reportsLeftNavMenu &&
            this.props.reportsLeftNavMenu.length && this.props.reportsLeftNavMenu[0].powerBiReports && this.props.reportsLeftNavMenu[0].powerBiReports.length && this.props.toggles.includes(TOGGLENAME.BIRSTDASHBOARDS)) {
            if (this.props.reportsLeftNavMenu.find(r => r.leftNavName === 'Incident Management')) {
                if (this.props.reportsLeftNavMenu.find(r => r.leftNavName === 'Incident Management')?.powerBiReports.find(r => r.displayName === 'KPIs')) {
                    insightAppHistory.push(`${pathPrefix}${computePowerBiReportRoutePath('Incident Management', 'KPIs')}`)
                }
                else {
                    insightAppHistory.push(`${pathPrefix}${computePowerBiReportRoutePath('Incident Management', this.props.reportsLeftNavMenu.filter(r => r.leftNavName === 'Incident Management')[0].powerBiReports[0].displayName)}`)
                }
            }
            else if (this.props.reportsLeftNavMenu.find(r => r.leftNavName === 'Disclosure Management')) {
                if (this.props.reportsLeftNavMenu.find(r => r.leftNavName === 'Disclosure Management')?.powerBiReports.find(r => r.displayName === 'Campaign Overview')) {
                    insightAppHistory.push(`${pathPrefix}${computePowerBiReportRoutePath('Disclosure Management', 'Campaign Overview')}`)
                }
                else {
                    insightAppHistory.push(`${pathPrefix}${computePowerBiReportRoutePath('Disclosure Management', this.props.reportsLeftNavMenu.filter(r => r.leftNavName === 'Disclosure Management')[0].powerBiReports[0].displayName)}`)
                }
            }
            else if (this.props.reportsLeftNavMenu.find(r => r.leftNavName === 'RiskRate')) {
                if (this.props.reportsLeftNavMenu.find(r => r.leftNavName === 'RiskRate')?.powerBiReports.find(r => r.displayName === 'Third Parties')) {
                    insightAppHistory.push(`${pathPrefix}${computePowerBiReportRoutePath('RiskRate', 'Third Parties')}`)
                }
                else {
                    insightAppHistory.push(`${pathPrefix}${computePowerBiReportRoutePath('RiskRate', this.props.reportsLeftNavMenu.filter(r => r.leftNavName === 'RiskRate')[0].powerBiReports[0].displayName)}`)
                }
            }
            else
                insightAppHistory.push(`${pathPrefix}${computePowerBiReportRoutePath(this.props.reportsLeftNavMenu[0].leftNavName, this.props.reportsLeftNavMenu[0].powerBiReports[0].displayName)}`)
        }

        const dashboardNavTree = this.props.canViewDashboards ? computeDashboardNavTree(this.props.toggles, this.props.dashboardCollections) : null

        const favicon = document.getElementById('faviconId')

        if (favicon != null) {
            favicon.setAttribute('href', "/favicon_Phase2.ico");
        }

        if (dashboardNavTree) {
            for (let dashboardRoute of dashboardNavTree) {
                if (!dashboardRoute || !dashboardRoute.subRoutes) continue
                for (let subRoute of dashboardRoute.subRoutes) {
                    subRoute.clickedAction = () => {

                        if (!window.location.pathname.startsWith(`${pathPrefix}/Dashboards/`) && subRoute.path) {
                            insightAppHistory.push(subRoute.path)

                        } else {
                            if (subRoute.data) {
                                const { collectionName, spaceId, page } = subRoute.data

                                var prefix = "NAVEX One"
                                document.title = prefix + " - " + page

                                const hasSpaceIdchanged = spaceId !== this.state.currentSpaceId

                                if (hasSpaceIdchanged) {
                                    insightAppHistory.push(`${pathPrefix}${computeBirstDashboardRoutePath(collectionName, page)}`)
                                }
                                else {
                                    window.history.pushState("", page, `${pathPrefix}${computeBirstDashboardRoutePath(collectionName, page)}`)
                                }

                                this.props.pubLeftNavClickEvent({ collectionName, spaceId, page, hasSpaceIdchanged })
                            }
                        }

                        this.setState({ currentSpaceId: subRoute!.data!.spaceId })
                        this.props.setCurrentPage(subRoute.title || "")
                    }

                    subRoute.data = {
                        ...subRoute.data,
                        active: this.props.currentPage && subRoute.title === this.props.currentPage
                    }
                }
            }
        }

        const powerBiReportNavTree = Array.isArray(this.props.reportsLeftNavMenu) ? computePowerBiDashboardNavTree(this.props.toggles, this.props.reportsLeftNavMenu) : null

        if (powerBiReportNavTree) {
            for (let powerBiReportRoute of powerBiReportNavTree) {
                if (!powerBiReportRoute || !powerBiReportRoute.subRoutes) continue
                for (let subRoute of powerBiReportRoute.subRoutes) {
                    subRoute.clickedAction = () => {

                        if (!window.location.pathname.startsWith(`${pathPrefix}/Reports/`) && subRoute.path) {
                            insightAppHistory.push(subRoute.path);
                        } else {
                            if (subRoute.data) {
                                const { leftNavName, page, displayName } = subRoute.data
                                var prefix = "NAVEX One"
                                document.title = prefix + " - " + page
                                insightAppHistory.push(`${pathPrefix}${computePowerBiReportRoutePath(leftNavName, displayName)}`)
                                window.history.pushState("", page, `${pathPrefix}${computePowerBiReportRoutePath(leftNavName, displayName)}`)
                                this.setState({ reportName: displayName })
                            }
                        }
                        this.props.setCurrentPage(subRoute.title || "")
                    }
                    subRoute.data = {
                        ...subRoute.data,
                        active: this.props.currentPage && subRoute.title === this.props.currentPage
                    }
                }
            }
        }

        const adminNodes = computeAdminNavNodes(this.props.permissions, this.props.toggles, this.props.featureConfigurations).adminNodes

        for (let node of adminNodes) {
            node.clickedAction = () => {
                insightAppHistory.push(node.path || "/")
                this.props.setCurrentPage(node.title || "")
            }
        }

        const hasAdminNodes = adminNodes.length > 0
        const adminTree = !hasAdminNodes ? [] : [{
            id: "route_admin",
            title: "Admin",
            icon: faLock,
            subRoutes: adminNodes
        }]

        const favoritesNode: IAppNavigationUIRoute = {
            id: "route_favorite",
            title: "My Favorites",
            icon: faStar,
            path: '/MyFavorites',
            component: () => <FavoriteRoute />
        }

        const crossProductReportNode: IAppNavigationUIRoute = {
            id: "route_crossproductreport",
            title: "NAVEX One Insights",
            icon: faChartBar,
            path: `/Reports/${encodeURIComponent("NAVEX One Insights")}_${encodeURIComponent("NAVEX One Insights")}`,
            component: (props: any) => <PowerBIEmbeddedDashboard {...props} toggles={this.props.toggles} reportsLeftNavMenu={this.props.reportsLeftNavMenu} productPermissions={this.props.productPermissions} />,
            clickedAction: () => { this.setState({ reportName: "NAVEX One Insights" }) }
        }

        favoritesNode.clickedAction = () => {
            insightAppHistory.push(favoritesNode.path || "/")
            this.props.setCurrentPage(favoritesNode.title || "")
        }

        const reportManagementNode: IAppNavigationUIRoute = {
            id: "route_report_management",
            title: "Report Management",
            icon: faLock,
            path: '/ReportManagement_ManageReports',
            component: (props: any) => <ManagePowerBIRoute {...props} permissions={this.props.permissions} />,
        }

        reportManagementNode.clickedAction = () => {
            insightAppHistory.push(reportManagementNode.path || "/")
            this.props.setCurrentPage(reportManagementNode.title || "")
        }

        const manageSubscriptionNode: IAppNavigationUIRoute = {
            id: "route_manage_subscription",
            title: "Manage Subscription",
            icon: faLock,
            path: '/Manage_Subscription/*',
            component: (props: any) => <SubscriptionRoute {...props} reportsLeftNavMenu={this.props.reportsLeftNavMenu} email ={this.props.email}/>
        }

        manageSubscriptionNode.clickedAction = () => {
            insightAppHistory.push(manageSubscriptionNode.path || "/")
            this.props.setCurrentPage(manageSubscriptionNode.title || "")
        }

        const rolesNode: IAppNavigationUIRoute = {
            id: "route_Roles",
            title: "Roles",
            icon: faLock,
            path: '/Roles',
            component: (props: any) => <RolesRoute {...props} toggles={this.props.toggles} />
        }

        rolesNode.clickedAction = () => {
            insightAppHistory.push(rolesNode.path || "/")
            this.props.setCurrentPage(rolesNode.title || "")
        }

        const dashboardManagementNodes = computeDashboardManagementLeftNavNodes(this.props.permissions, this.props.toggles).dashboardManagementNodes

        for (let node of dashboardManagementNodes) {
            node.clickedAction = () => {
                insightAppHistory.push(node.path || "/")
                this.props.setCurrentPage(node.title || "")
            }
        }

        const hasDashboardManagementNodes = dashboardManagementNodes.length > 0
        const dashboardManagementTree = !hasDashboardManagementNodes ? [] : [{
            id: "route_dashboard_management",
            title: "Dashboard Management",
            icon: faLock,
            subRoutes: dashboardManagementNodes
        }]

        const datasetManagementNodes = computeDatasetManagementLeftNavNodes(this.props.permissions, this.props.toggles, this.props.featureConfigurations).datasetManagementNodes

        for (let node of datasetManagementNodes) {
            node.clickedAction = () => {
                insightAppHistory.push(node.path || "/")
                this.props.setCurrentPage(node.title || "")
            }
        }

        const hasDatasetManagementNodes = datasetManagementNodes.length > 0

        const DatasetManagementTree = !hasDatasetManagementNodes ? [] : [{
            id: "route_dataset_management",
            title: "Dataset Management",
            icon: faLock,
            subRoutes: datasetManagementNodes
        }]

        const setPathPrefix = (route: IAppNavigationUIRoute): IAppNavigationUIRoute => {
            if (route.subRoutes?.length) {
                route.subRoutes.forEach(setPathPrefix)
            } else {
                route.path = route.path?.replace(/^\//, `${pathPrefix}/`)
                route.aliasPaths = route.aliasPaths?.map(path => path.replace(/^\//, `${pathPrefix}/`))
            }

            return route

        }
        const computeSideMenuRoutes = (): IAppNavigationUIRoute[] => {
            const result: IAppNavigationUIRoute[] = []
            if (!(this.props.toggles.includes(TOGGLENAME.BIRSTDASHBOARDS)))
                result.push(...dashboardNavTree ? dashboardNavTree.sort((a, b) => String(a.title) > String(b.title) ? 1 : -1) : [])
            if (this.props.toggles.includes(TOGGLENAME.POWERBILEFTNAV))
                result.push(...powerBiReportNavTree ? powerBiReportNavTree.sort((a, b) => String(a.title) > String(b.title) ? 1 : -1) : [])
            if (this.props.toggles.includes(TOGGLENAME.POWERBILEFTNAV) && this.props.toggles.includes(TOGGLENAME.CROSSPRODUCT) && ((this.props.reportsLeftNavMenu?.filter(r => r.leftNavName === "NAVEX One Insights").length ?? 0) > 0))
                result.push(crossProductReportNode)
            if (this.props.toggles.includes(TOGGLENAME.POWERBIPERMISSIONS) && this.props.permissions && this.props.permissions.includes('Favorite'))
                result.push(favoritesNode)
            if (this.props.toggles.includes(TOGGLENAME.POWERBIREPORTMANAGEMENT) && this.props.permissions && this.props.permissions.includes("ReportManagement"))
                result.push(reportManagementNode)
            if (this.props.toggles.includes(TOGGLENAME.SUBSCRIBEBYEMAIL) && this.props.permissions && this.props.permissions.includes("Subscribe"))
                result.push(manageSubscriptionNode)
            if (this.props.toggles.includes(TOGGLENAME.POWERBIDATASETMANAGEMENT))
                result.push(...DatasetManagementTree)
            if (!(this.props.toggles.includes(TOGGLENAME.BIRSTDASHBOARDMANAGEMENT)))
                result.push(...dashboardManagementTree)
            if (this.props.permissions && this.props.permissions.includes("RolesEdit"))
                result.push(rolesNode)
            if (!(this.props.toggles.includes(TOGGLENAME.BIRSTLEFTNAV)))
                result.push(...adminTree ? adminTree : [])

            const resultWithPathPrefix = result.map(setPathPrefix)

            return resultWithPathPrefix
        }

        const userMenuRoutes: IAppNavigationUIRoute[] = []

        const userMenuEnabled = !this.props.puid

        let showAppNavigationUI = true
        document.title = "NAVEX One"
        return (
            showAppNavigationUI
                ?
                <AppNavigationUI
                    id="Insights"
                    appName="NAVEX One"
                    showOldLogo={false}
                    backToGatewayEnabled={false}
                    backToGatewayUrl={undefined}
                    headerContents={
                        <HeaderContents puid={this.props.puid} accessToken={this.props.authorizationToken} useAuth0AsmCutover={this.props.toggles.includes(TOGGLENAME.AUTHLIBASMCUTOVER)}
                            tenantId={this.props.tenantId} />
                    }
                    headerLogoSrc="./assets/img/header-logo.svg"
                    userMenuDisplayName={this.buildDisplayName()}
                    userMenuEnabled={userMenuEnabled}
                    userMenuRoutes={userMenuRoutes}
                    sideMenuRoutes={computeSideMenuRoutes()}
                    otherRoutes={[
                        { id: "dashboards", path: `${pathPrefix}/Dashboards/*`, component: BirstEmbeddedDashboard, title: "???" },
                        { id: "Roles", path: `${pathPrefix}/Roles`, component: NoPersmissionError, title: "Roles" },
                        { id: "powerBiReport", path: `${pathPrefix}/Reports/*`, component: (props: any) => <PowerBIEmbeddedDashboard {...props} toggles={this.props.toggles} reportsLeftNavMenu={this.props.reportsLeftNavMenu} productPermissions={this.props.productPermissions} email={this.props.email}/>, title: this.props.currentPage || this.state.reportName },
                        { id: "route_manage_subscription", path: `${pathPrefix}/Manage_Subscription/*`, component: (props: any) => < SubscriptionRoute {...props} reportsLeftNavMenu={this.props.reportsLeftNavMenu} email={this.props.email}/>, title: this.props.currentPage }
                    ]}
                    titleOverride={this.getTitle()}
                    historyOverride={insightAppHistory}
                />
                :
                <BrowserRouter>
                    <Routes>
                        <Route id="viewDashboardRoute" path={`${pathPrefix}/DashboardManagement_EditDashboard/*`} Component={(props: any) => <ViewDashboardRoute {...props} toggles={this.props.toggles} />} />
                    </Routes>
                </BrowserRouter>
        )
    }
}

// ===== REDUX CONTAINER =====
export function mapStateToProps(state: IGlobalState): IStateDependentProps {
    return {
        firstName: state.authentication.firstName,
        lastName: state.authentication.lastName,
        username: state.authentication.username,
        email: state.authentication.email,
        puid: state.authentication.puid,
        dashboardCollections: state.authentication.dashboardCollections,
        defaultDashboardCollection: state.authentication.defaultDashboardCollection,
        defaultDashboard: state.authentication.defaultDashboard,
        permissions: state.authentication.permissions,
        productPermissions: state.authentication.productPermissions,
        canViewDashboards: state && state.authentication && state.authentication!.permissions !== undefined && state.authentication!.permissions!.includes("DashboardView"),
        authenticationUnexpectedError: state && state.authentication && state.authentication.authenticationUnexpectedError,
        toggles: state.authentication.toggles || [],
        tenantId: state.authentication.tenantId,
        currentPage: state.insights.currentPage,
        authorizationToken: state.authentication.authorizationToken,
        reportsLeftNavMenu: state.authentication.reportsLeftNavMenu,
        featureConfigurations: state.authentication.featureConfigurations || []
    }
}

export const mapDispatchToProps = (dispatch: any): IPropsThatDispatch => {
    return {
        onAuthenticationConfirmed: userData => dispatch(AuthenticationActionCreators.confirmAuthentication(userData)),
        onErrorGettingAuthentication: errorKey => dispatch(AuthenticationActionCreators.errorGettingAuthentication(errorKey)),
        pubLeftNavClickEvent: (event: ILeftNavClickEvent) => dispatch(BirstIntegrationsActionCreators.pubLeftNavClickEvent(event)),
        setCurrentPage: (value: string) => dispatch(InsightsActionCreators.setCurrentPage(value)),
    }
}

export const InsightsApp = connect(mapStateToProps, mapDispatchToProps)(InsightsAppUnwrappedForTestingOnly)