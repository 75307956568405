import React from 'react'
import { CustomFormulasRoute } from '../../PowerBICustomFormulas/CustomFormulasRoute'
import { IDatasetManagementScreenDefinitions } from './datasetManagementScreenTypes'
import { OutcomesRoute } from '../../Outcomes'
import { CustomFieldsRoute } from '../../PowerBiCustomFieldsMappings/CustomFieldsRoute'
import { TOGGLENAME } from '../../../ts/enums/releaseToggles'
import { FiscalCalendarRoute } from '../../FiscalCalendar/FiscalCalendarRoute'

export const computeDatasetManagementDefinitions = (features: string[],toggles: string[]) : IDatasetManagementScreenDefinitions => ({
    Customformulas: {
        title: "Custom Formulas",
        getingPermission: "CustomFormulas",
        component: (props: any) => <CustomFormulasRoute {...props} toggles={toggles} />
    },
    CustomFieldMappings: {
        title: "Custom Fields",
        getingPermission: "CustomFieldMappingsEdit",
        component: (props: any) => <CustomFieldsRoute {...props} toggles={toggles} />,
        toggleName : TOGGLENAME.POWERBICUSTOMFIELDMAPPING
    },
    CustomCalendar: {
        title: "Custom Calendar", 
        getingPermission: features.filter(item => item === "FiscalCalendar").length > 0 ? "FiscalCalendar" : "",
        component: (props: any) => <FiscalCalendarRoute {...props} toggles={toggles} />,
        featureName: features.filter(item => item === "FiscalCalendar").length > 0 ? "FiscalCalendar" : "",
    },
    SubstantiatedTypeMapping: {
        title: "Outcomes",
        getingPermission: "OutcomeMappingsEdit",
        component: (props: any) => <OutcomesRoute {...props} toggles={toggles} />
    }
})